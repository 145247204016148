.twitter-share-button {
    align-self: center;
    background: #1DA1F2;
    border-radius: 12px;
    color: white;
    display: block;
    font-family: 'Montserrat', sans-serif;
    width: 110px;
    padding: 8px 12px;
    text-align: center;
}

.twitter-share-button svg {
    display: inline-block;
    margin-right: 10px;
    height: 20px;
    width: 20px;
}
